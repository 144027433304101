"use client";
import type { ReactElement } from "react";
import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import timezones from "@/constant/timezone.json";
import { createContext } from "@/helpers/createContext";
import { compareNumber } from "@/helpers";
import useLocalStorage from "@/hooks/useLocalStorage";

export interface TimezoneSettingType {
  text: string;
  offset: number;
  utc: string;
}

interface TimezoneContext {
  timezoneSettings: TimezoneSettingType[];
  timezone: TimezoneSettingType;
  onChangeTimezone: (tz: string) => void;
}

const [Provider, useTimezoneContext] = createContext<TimezoneContext>();

export const TimeProvider = ({ children }: { children: ReactElement }) => {
  const [data, onUpdate] = useLocalStorage<{ saveUtc?: string }>("_klarda_time_zone", {});

  const timezoneSettings = useMemo(
    () =>
      timezones
        .sort((t1, t2) => compareNumber(t1.offset, t2.offset))
        .reduce((sum, t) => (sum.some(ts => ts.text === t.text) ? sum : [...sum, t]), [] as any[])
        .map(
          t =>
            ({
              text: t.text.split(")")[0].replace("(", ""),
              utc: t.utc[0],
              offset: t.offset * 60,
            } as TimezoneSettingType),
        ),
    [],
  );

  const [timezone, setTimezone] = useState<TimezoneSettingType>(timezoneSettings[0]);

  useEffect(() => {
    const { saveUtc } = data;
    const saveSetting = timezoneSettings.find(s => s.utc === saveUtc);
    if (saveSetting) {
      setTimezone(saveSetting);
    } else {
      const utcOffset = dayjs().utcOffset();
      const localSetting = timezoneSettings.find(s => s.offset === utcOffset);
      if (localSetting) {
        setTimezone(localSetting);
      } else {
        setTimezone(timezoneSettings.find(s => s.text === "UTC+07:00") as TimezoneSettingType);
      }
    }
  }, [timezoneSettings, data]);

  return (
    <Provider
      value={{
        timezoneSettings,
        timezone,
        onChangeTimezone: tz => {
          const time = timezoneSettings.find(s => s.text === tz) as TimezoneSettingType;
          onUpdate({ saveUtc: time.utc });
        },
      }}
    >
      {children}
    </Provider>
  );
};

export { useTimezoneContext };
export default TimeProvider;
